
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
  background: rgb(230,230,230);
  /* background: -moz-linear-gradient(top, rgba(3,60,91,1) 0%, rgba(17,86,128,1) 79%, rgba(17,87,129,1) 100%);
   background: -webkit-linear-gradient(top, rgba(3,60,91,1) 0%,rgba(17,86,128,1) 79%,rgba(17,87,129,1) 100%);
   background: linear-gradient(to bottom, rgba(3,60,91,1) 0%,rgba(17,86,128,1) 79%,rgba(17,87,129,1) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#033c5b', endColorstr='#115781',GradientType=0 );
   font-family: 'Roboto', sans-serif!important;

   padding-top: 42px!important;*/
  font-family: Arial, Helvetica, sans-serif;
  min-height: calc(690px * (90/150));
  height: 100vh;
}
.navbar-inverse .navbar-toggle{
  border-color:#ffffff;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover{
  background-color:#00699C;
}
.navbar-inverse .navbar-nav>li>a {
  color: #00699C;
}
.navbar-inverse .navbar-nav>li>a:hover {
  color: #0792D5;
}
.navbar-inverse .navbar-nav>.open>a:hover, .navbar-inverse .navbar-nav>.open>a:active,  .navbar-inverse .navbar-nav>.open>a:visited {
  color: #0792D5;
}
#navi{
  background-color:transparent;
  color: #ffffff;
  min-height: 80px;
  border: none;
}
/*.navbar-brand img{
  width:100%;
}
*/
.container-custom{

  margin-right: auto;
  margin-left: auto;
}
.content{
  padding: 20vh 0 10vh;
}
/*#log-in{
   float:right;
    display: block;
    position: relative;
    bottom: 60px;
}*/
#log-in li a img:first-child{
  margin-right:15px;
  width: 30px;
}
.dropdown-menu{
  background: transparent;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{
  background: transparent;
  color:#0792D5;
}
.open .dropdown-toggle{
  color: #fff;
  background-color: transparent!important;
}
.dropdown-menu li a{
  color:#F98F00;
  background:transparent;
  text-align:center;

}
.title{
  /* text-decoration: none;
  display: inline-block;
  right: 5%;*/

  text-decoration: none;
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-bottom: 25px;

}

.title:focus, .title:focus, .title:hover, .title:active{
  text-decoration: none;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px;
  color: transparent;
}
.title  a {
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  /*color: rgba(0,61,92,1);
  color: -moz-linear-gradient(top, rgba(0,61,92,1) 0%, rgba(0,61,92,0.95) 47%, rgba(0,61,92,0.95) 51%, rgba(0,61,92,0.93) 71%, rgba(0,61,92,0.9) 100%);
  color: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,61,92,1)), color-stop(47%, rgba(0,61,92,0.95)), color-stop(51%, rgba(0,61,92,0.95)), color-stop(71%, rgba(0,61,92,0.93)), color-stop(100%, rgba(0,61,92,0.9)));
  color: -webkit-linear-gradient(top, rgba(0,61,92,1) 0%, rgba(0,61,92,0.95) 47%, rgba(0,61,92,0.95) 51%, rgba(0,61,92,0.93) 71%, rgba(0,61,92,0.9) 100%);
  color: -o-linear-gradient(top, rgba(0,61,92,1) 0%, rgba(0,61,92,0.95) 47%, rgba(0,61,92,0.95) 51%, rgba(0,61,92,0.93) 71%, rgba(0,61,92,0.9) 100%);
  color: -ms-linear-gradient(top, rgba(0,61,92,1) 0%, rgba(0,61,92,0.95) 47%, rgba(0,61,92,0.95) 51%, rgba(0,61,92,0.93) 71%, rgba(0,61,92,0.9) 100%);
  color: linear-gradient(to bottom, rgba(0,61,92,1) 0%, rgba(0,61,92,0.95) 47%, rgba(0,61,92,0.95) 51%, rgba(0,61,92,0.93) 71%, rgba(0,61,92,0.9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003d5c', endColorstr='#003d5c', GradientType=0 );*/
  color:#00699C;
  text-transform:uppercase;
  text-decoration: none;
  margin: 0 auto;
  padding-top: 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  /*font-style: italic;
  font-family: 'Roboto', sans-serif;*/
}
.left-item {
  background-color: #00699C;
  /*padding: 9.3vh 8vh;*/
  padding: 6.3vh 23vh;
  margin-bottom: 15px;
  /*height:408px;*/

}
.left-icon{
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 225px;
  height:200px;
}
.left-item h2 {
  font-size: 22px;
  text-align: center;
  padding-top: 0px;
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  color:#fff;
  font-weight: 100;
}
.right-icon{
  text-align: center;
  background: #00699C;
  display: block;
  padding: 0vh 2vh;
  max-height:200px;
  width:100%;
  margin: 2px;
}
.right-icon a {
  text-decoration: none;
}
.right-icon a:hover{
  text-decoration: none;
}

.right-icon img{
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 50px;
  min-height: 70px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.right-icon img.bull{
  max-width: 70px;
}
/* .right-item{
margin-bottom:15px;
}*/
.right-icon h2{
  font-size: 13px;
  color:#fff;
  font-weight:100;
  min-height: 30px;
  margin:-3px;
}
.item:hover, .item:active, .item:focus{
  background-color: #29ABE2;
  text-decoration: none;
}
.no-padding-right{
  padding-right:0px!important;
}
.parent-div {
  .full-item {
    margin-bottom: 15px;
    h2 {
      font-size: 20px;
      text-align: left;
      padding-top: 0px;
      margin-top: 0px;
      font-family: 'Roboto', sans-serif;
      color:#fff;
      font-weight: 100;
      position: relative;
      top: 37%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
    img{
      margin: 0 auto;
      display: block;
      width: 100%;
      max-width: 120px;
      height: 120px;
      font-size: 100px;
      color: #fff;
      float: right;
      text-align: right;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .no-padding-profil {
    padding: 0!important;
  }
  .navbar-brand {
    float: left;
    height: 50px;
    padding: 0px 12px;
    font-size: 18px;
    line-height: 20px;
  }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width : 1024px) and (max-width : 1200px)  {
  .right-icon h2 {
    font-size: 11px;
    color: #fff;
  }
  .right-icon img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 41px;
    /*min-height: 100px;*/
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .right-icon {
    text-align: center;
    background: #00699C;
    display: block;
    padding: 2vh 0vh;
  }
  .left-item {
    background-color: #00699C;
    padding: 7.3vh 10vh;
    margin-bottom: 15px;
  }

}

@media only screen and (min-width : 1200px) and (max-width : 1280px)  {
  .right-icon {
    text-align: center;
    background: #00699C;
    display: block;
    padding: 4vh 2vh;
  }
  .right-icon h2 {
    font-size: 11px;
    color: #fff;
  }
  .right-icon img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 46px;
    /*min-height: 90px;*/
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .left-item {
    background-color: #00699C;
    padding: 7.3vh 8vh;
    margin-bottom: 15px;
  }
  .titleprofil{
    margin-top: 8px;
  }
}
@media only screen and (min-width : 1281px) and (max-width : 1680px) {
  .left-item {
    background-color: #00699C;
    padding: 9.3vh 15vh;
    margin-bottom: 15px;

  }
  .right-icon {
    text-align: center;
    background: #00699C;
    display: block;
    padding: 3vh 1vh;
  }
  .right-icon img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 50px;
    /*min-height: 100px;*/
    margin-top: 20px;
    margin-bottom: 20px;
  }
  /*.right-item {
    margin-bottom: 38px;
  }*/

}
@media only screen and (min-width : 1366px) and (max-width : 1400px) {
  .right-item {
    margin-bottom: 14px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .content {
    padding: 5vh 0 10vh;
  }

  .right-icon img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 50px;
    /* min-height: 134px;*/
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .navbar-header{
    min-height: 50px;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
/* side-bar*/
.sidebar{
  display: block;
  list-style: none;
  padding: 3vh 0 3vh;

}
.sidebar a p{
  margin: auto;
  text-align: center;
  display: block;
  left: 30px;
  position: relative;
}
.sidebar a:hover {
  color:#00699C;
  text-decoration:none;
}
.sidehover{
  background: url('../img/truck.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sidebar1{
  display:block;
}
.sidehover:hover, .sidebar0 .active{
  background: url('../img/truckblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  color:#00699C;
  cursor:pointer;
}


.sidehover1{
  background: url('../img/bord.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size:30px;
}
.sidehover1:hover, .sidebar1 .active{
  background: url('../img/bordblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size:30px;
  color:#00699C;
  cursor:pointer;
}
.sidehover2{
  background: url('../img/round.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sidehover2:hover, .sidebar2 .active{
  background: url('../img/roundblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size:40px;
  color:#00699C;
  cursor:pointer;
}
.sidehover3{
  background: url('../img/promo.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sidehover3:hover, .sidebar3 .active{
  background: url('../img/promoblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size:40px;
  color:#00699C;
  cursor:pointer;
}
.sidehover4{
  background: url('../img/material.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sidehover4:hover, .sidebar4 .active{
  background: url('../img/materialblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size:40px;
  color:#00699C;
  cursor:pointer;
}
.sidehover5{
  background: url('../img/forms.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 35px;
}
.sidehover5:hover, .sidebar5 .active{
  background: url('../img/formsblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size:35px;
  color:#00699C;
  cursor:pointer;
}
.sidehover6{
  background: url('../img/competitor.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sidehover6:hover, .sidebar6 .active{
  background: url('../img/competitorblue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size: 40px;
  color:#00699C;
  cursor:pointer;
}
.sidehover8{
  background: url('../img/bull.svg') no-repeat ;
  background-position: 10% 45%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 70px;
}
.sidehover8:hover, .sidebar8 .active{
  background: url('../img/bull-blue.svg') no-repeat #cdcdcd;
  background-position: 10% 45%;
  background-size: 70px;
  color:#00699C;
  cursor:pointer;
}
.sidehover9{
  background: url('../img/svg/pdf.svg') no-repeat ;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover9:hover, .sidebar9 .active{
  background: url('../img/svg/pdf-blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.sidehover12{
  background: url('../img/calculator.svg') no-repeat;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover12:hover, .sidebar12 .active{
  background: url('../img/calculator_blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.sidehover13{
  background: url('../img/icons8-info.svg') no-repeat;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover13:hover, .sidebar13 .active{
  background: url('../img/icons8-info_blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.sidehover14{
  background: url('../img/animations_487282.svg') no-repeat;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover14:hover, .sidebar14 .active{
  background: url('../img/animations_487282_blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.sidehover10{
  background: url('../img/svg/billboard.svg') no-repeat ;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover10:hover, .sidebar10 .active{
  background: url('../img/svg/billboard-blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.sidehover11{
  background: url('../img/svg/settings.svg') no-repeat ;
  background-position: 10% 50%;
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 50px;
}
.sidehover11:hover, .sidebar11 .active{
  background: url('../img/svg/settings-blue.svg') no-repeat #cdcdcd;
  background-position: 10% 50%;
  background-size: 50px;
  color:#00699C;
  cursor:pointer;
}
.item-details{
  display: block;
  list-style: none;
  /*padding: 2.5vh 0vh;*/
  -webkit-padding-start: 0;
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 0;

}
.section-title{
  padding: 2.5vh 37px 2.5vh;
  background: #fff;
  display: block;
  margin-bottom: 15px;
  color:#005680;
}


.section-title h1 {
  margin: 0 auto;
  text-align: center;
  color: #005680;
  font-weight: 300;
  font-size: 30px;
  font-family: "Roboto" !important;
}
.section-subtitle{
  padding: 2.5vh 37px 2.5vh;
  background: #00699C;
  display: block;
  margin-bottom: 15px;
  color: #fff;

}
.section-subtitle h3{
  color: #fff;
  font-weight: 200;
  font-family: "Roboto" !important;
  font-size: 21px;
  text-align: center;
}
.section-title h3{
  margin: 0 auto;
  text-align: center;
  color: #00699C;
  font-weight: 600;
  font-size:22px;
  font-family:'Roboto'!important;
}
.blue{
  padding: 2.5vh 37px 2.5vh;
  background:#005680 ;
  display: block;
  margin-bottom: 15px;
  color: #fff;

}

.blue p{
  font-size: 15px;
  font-weight: 200;
}

.select-competitor p {
  color: #003C5A;
  margin: 25px;
  font-size: 14px;
  font-weight: 500;
}
.select-competitor .competitor-image p{
  color: #003C5A;
  margin: 25px 1px 25px 1px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;

}
.part p{
  color: #003C5A;
  margin: 10px 25px ;
  font-size: 12px;
}
.price-competitor p{
  color: #003C5A;
  margin: 10px 25px;
  font-size: 12px;
}
.price-competitor .btn{
  border-radius: 0px;
}
.select-competitor{
  background: #fff;
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
}
.competitor-image{
  border:2px solid #CCCCCC;
  position:relative;
  margin:10px 0;
  min-height:40px;
}
.competitor-image img{
  width: 100%;
  padding: 10px;
  margin-top:20px;
}
.thumbnail-sugested{
  width: 80%;
  margin: 25px;
}
.thumbnail-sugested img{
  max-width:200px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td, th {
  border: none;
  text-align: left;
  padding: 8px;
  font-size: 0.8em;
  font-weight: 200;
}
td{
  color:#00699C;

}
table .details:first-child {
  background-color: #00699C;
  color:#fff;

}
#sortable tr{
  cursor: move;
}
.details th{
  text-align:center;
}
.details td{
  text-align:center;
}
.center td{
  text-align:center;
}
.details-row td{
  text-align:center;
}
.details-row td input{
  text-align:center;
  width:100%!important;
}
.details-row td a{
  cursor:pointer;
}
tr:nth-child(even) {
  background-color: #E6EDF0;
}
tr{
  background-color:#fff;
}
td img{
  max-width: 30%;
  width:100%;
  height:auto;
}
tr td:first-child p{
  border: 2px solid #CCCCCC;
  text-align: center;
  padding: 8px;
}

.details td input{
  display: block;
  width: 100%;
}
.has-error input{
  border-radius:0px;
}
.has-error p, .has-error span{
  color: #a94442!important;
}
.has-error p{
  border-color: #a94442!important;
}
.has-error span.label{
  color:#00699C!important;
  font-size: 14px;
}
.add-prod{
  font-size:16px;
  color:#f98f00;
  margin-top: 15px;
  display: inline-block;
  font-size:14px;
  font-weight:100;
  cursor:pointer;
}
.add-prod img{
  position: relative;
  display: inline-block;
  margin-right: 10px;
}
.reset-link{
  margin-top: 15px;
  display: inline-block;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
  float: right;
  text-decoration: none;
  color:#0792d5;
}
.reset-link:hover{
  text-decoration: none;
}
.reset-link .glyphicon{
  font-size: 22px;
  top: 5px;
}
.bottom{
  text-align: right;
  right: 3%;
  position: relative;
  height: auto;
  display: inline-block;
  float: right;
}
.order{
  background-color: #0792d5;
  display: inline-block;
  margin-top: 15px;
  color:#fff;
  text-decoration:none;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.order:hover{
  background: darken( #0792d5, 10% );
  text-decoration:none;
  color:#fff;

}
.order:focus {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 0px;
  text-decoration:none;
}

.order:focus, .order:hover {
  color: #fff;
  text-decoration: none;
}
.details-row td a .glyphicon {
  font-size: 22px;
}
.delete-btn .glyphicon {
  font-size: 22px;
}
.total{
  color:#0792d5;
  margin-top: 20px;
}
/*product image css*/

.search-id{
  background-color:#00699C;
  padding: 2.5vh 37px 6.5vh;
  display: block;
}
.btn {
  border-radius: 0px!important;
}
.search-id div .input-group .form-control{
  background-color: transparent;
  background-image: none;
  height:38px;
  border: 1px solid #fff;
  border-radius: 3px;
  color:#fff;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-id div .btn-default{
  color: #333;
  background-color: transparent;
  border-color: #fff;
  padding: 8px 12px;
  border-left: transparent;
}
.box-image{
  border: 1px solid #fff;
  margin:10px 0;
  background:#fff;
}
.box-image .thumbnail img{
  /* position: absolute;
   width: 100%;
   top: 50%;
   transform: translatey(-50%);*/
  height:auto;
  max-height: 205px;
}
@media screen and (max-width: 760px) {

  .box-image .thumbnail img {
    /* width: 41%;
     width: 80%;*/
    top: 50%;
  }

}
.caption p .btn-primary{
  width:100%;
  color:#FFF;
  background-color:#0792D5;
  font-size:12px;
  font-weight:100;
}
.product-name{
  color: #0792D5;
  margin: 20px 0 10px 0;
  display: block;
  height:20px;
  overflow:hidden;
}
.box-image .promo-pdf-name{
  height:40px;
}
.box-image .thumbnail {
  position:relative;
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background: #fff;
  border: none;
  min-height:214px;
  border-radius: 0px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
}
.box-image .logo-height .logo-section{
  height:auto;

}

.box-image .promo-logo {
  background: transparent;
}
/*.box-image .promo-logo img{
    height:110px;
}*/
.caption p:nth-child(2){
  font-size: 10px;
  color:#0792D5;
  opacity: 0.6;
}
.btn img{
  margin-left: 12px
}
.admin-list .panel-primary{
  background-color:#ffffff;
}
/*Sign In*/

.form-container{
  background-color:#00699C;
}
.panel-primary{
  background-color:#00699C;
  border-radius:0px;
  border:0px
}
.no-bord .panel{
  background-color:transparent;
  border-radius:0px;
  border:0px
}
/*.col-md-6 .form-control{
  background-color: transparent;
  color: #fff;

}*/
.panel-reset{
  background-color: #00699C!important;
}
.col-md-offset-2 .panel{
  background-color: #00699C!important;
}
.form-control::placeholder{
  color: rgba( #fff, .7 );
  font-size: 10px;
}
.form-group .control-label{
  color:#fff;
  font-weight:300;
  font-size:14px;
}
.custom-buton{
  text-align:center;

}
.custom-buton .btn-primary{
  background-color: #F98F00;
  padding: 15px 60px;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
}
.remember{
  color: rgba( #fff, .7 );
}
.btn-link{
  color: #fff;
}
.btn .btn-link:focus, .btn-link:hover{
  color:#fff;
}

/*settings*/
.sidebar-set{
  display: block;
  list-style: none;
  padding: 3vh 0 3vh;
}
.sidebar-set a{
  margin: auto;
  text-align: right;
  position: relative;
  font-size:14px;
  margin-bottom:10px;
}
.sidebar-set a p{
  margin: auto;
  text-align: center;
  display: block;
  left: 13px;
  position: relative;
}
.sideadmin0{
  /* padding: 2.5vh 4vh 2.5vh; */
  margin-bottom: 15px;
  background-color: #00699C;
  display: block;
  color: #fff;
  cursor: pointer;
  background-size: 40px;
  height: 100%;
  min-height: 60px;
  display: flow-root;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}
.sideadmin0 .fa {
  font-size:30px;
  display:block;
  position:absolute;
  bottom:15px;
  left:12px;
}
.sideadmin0:hover, .sidebar-set .active{
  background:#cdcdcd;
  color:#00699C ;
  text-decoration: none;
}

.sideadmin1{
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sideadmin1 .fa {
  font-size:30px;
  display:block;
  position:absolute;
  bottom:15px;
}
.sideadmin1:hover{
  background:#cdcdcd;
  color:#00699C;
  text-decoration: none;
}
/*.sideset1, .sideset4, .sideset5{
  padding: 2.5vh 4vh 2.5vh;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}*/
.sideset1{
  background: url('img/svg/general-info-01.svg')  no-repeat ;
  background-position: 10px 13px;
  padding:  10px 4vh 5px;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;
  background-size: 40px;
}
.sideset1:hover, .sidebar-set1 .active{
  background: url('img/svg/general-info-01.svg') no-repeat #cdcdcd;
  background-position: 10px -36px;
  color:#00699C;
  text-decoration: none;
}
.sideset2{
  background: url('img/svg/company-users-01.svg')  no-repeat ;
  background-position: 10px 6px;
  padding:  10px 4vh 5px;
  margin-bottom: 15px;
  background-color: #00699C;
  display:block;
  color:#fff;
  cursor:pointer;

}
.sideset2:hover, .sidebar-set2 .active{
  background: url('img/svg/company-users-01.svg') no-repeat #cdcdcd;
  background-position: 10px -36px;
  color:#00699C;
  text-decoration: none;
}
.sideset3{
  background: url('img/svg/order-history-01.svg')  no-repeat ;
  background-position: 10px 5px;
  padding:  10px 4vh 5px;
  background-color: #00699C;
  color:#fff;
  cursor:pointer;
  display: block;
}
.sideset3:hover, .sidebar-set3 .active{
  background: url('img/svg/order-history-01.svg') no-repeat #cdcdcd;
  background-position: 10px -54px;
  color:#00699C;
  text-decoration: none;
}
.side-users1{
  background: url('/img/svg/admin-01.svg') no-repeat;
  background-position: 10px -41px;
  padding: 1.5vh 4vh 1.5vh;
  background-color: #fff;
  display: block;
  color: #00699C;
  cursor: pointer;
}
.side-users1:hover, .active-sidebar2{
  background: url('/img/svg/admin-01.svg') no-repeat #0792d5;
  background-position: 10px 0px;
  color:#fff;
  text-decoration: none;
}
.active-sidebar2{
  background: url('/img/svg/admin-01.svg') no-repeat #0792d5;
  background-position: 10px 0px;
  color:#fff;
  text-decoration: none;
}
.side-users3{
  background: url('/img/svg/new-user-01.svg') no-repeat;
  background-position: 10px -41px;
  padding: 1.5vh 4vh 1.5vh;
  background-color: #fff;
  display: block;
  color: #00699C;
  cursor: pointer;
}
.side-users3:hover{
  background: url('/img/svg/new-user-01.svg') no-repeat #0792d5;
  background-position: 10px 0px;
  color:#fff;
  text-decoration: none;

}

.users-right {
  background-color: #fff;
  padding: 2.5vh 0vh;
  display: block;
  margin: 1.8%;
}

.side-info{
  background-color: #fff;
  padding: 2.5vh 0vh;
  display: block;
  margin-top: 1.8%;
}
.side-info h1{
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px 20px;
}
.col-md-10 .panel {
  background-color: transparent;
  border-radius: 0px;
  border: 0px;
}
.col-md-5 .btn{
  background: #F98F00;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.col-md-5 .btn:hover {
  background: darken( #F98F00, 10% );

}
.col-md-8 .btn-primary:hover {
  background: darken( #F98F00, 10% );

}
.show .btn{
  background: #F98F00;
  padding: 2vh 5vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
  color:#fff;
}
.show .btn:hover {
  background: darken( #F98F00, 10% );
}
.show {
  margin-bottom:20px;
}
.col-md-8 .btn-primary{
  background: #F98F00;
  padding: 2vh 5vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.form-horizontal .btn-primary{
  background: #F98F00 ;
  padding: 1vh 5vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;margin:auto;
}
.form-horizontal .btn-primary:hover{
  background: darken( #F98F00, 10% )!important;
}
.users-info{
  margin-top: 1.5%;
}
.users-info .section-title h1{
  color:#fff;
  font-weight: 300;
  font-size: 30px;
}

.col-md-6 .btn-primary{
  background: #F98F00;
  padding: 1.5vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
  width:100%;
}


.import-btn .btn-success{
  color: #fff;
  background-color:#005680;
  border-color: #005680;
  padding: 1.5vh 2vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 5px;
}
.import-btn .btn-success:hover{
  background: darken( #F98F00, 10% );
}
.import h3{
  color: #005680;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto" !important;
}
.active-btn .btn-primary {
  background: #F98F00;
  padding: 0.5vh 1.5vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 300;
}
.active-btn {
  text-align: center;
}
.delete-btn{
  text-align:center;
}
.pag{
  margin:0 auto;
  text-align:center;
}


/*load animation*/

#ballsWaveIMG{
  position:relative;
  width:90px;
  height:21px;
  margin:auto;
  left: -26px;
  bottom: -46px;
}

.ballsWaveIMG{
  position:absolute;
  top:0;
  width:11px;
  height:11px;
  animation-name:ballsWaveIMG;
  -o-animation-name:ballsWaveIMG;
  -ms-animation-name:ballsWaveIMG;
  -webkit-animation-name:ballsWaveIMG;
  -moz-animation-name:ballsWaveIMG;
  animation-duration:1.5s;
  -o-animation-duration:1.5s;
  -ms-animation-duration:1.5s;
  -webkit-animation-duration:1.5s;
  -moz-animation-duration:1.5s;
  animation-iteration-count:infinite;
  -o-animation-iteration-count:infinite;
  -ms-animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-iteration-count:infinite;
  animation-direction:normal;
  -o-animation-direction:normal;
  -ms-animation-direction:normal;
  -webkit-animation-direction:normal;
  -moz-animation-direction:normal;
  border-radius:6px;
  -o-border-radius:6px;
  -ms-border-radius:6px;
  -webkit-border-radius:6px;
  -moz-border-radius:6px;
}

#ballsWaveIMG_1{
  left:0;
  animation-delay:0.6s;
  -o-animation-delay:0.6s;
  -ms-animation-delay:0.6s;
  -webkit-animation-delay:0.6s;
  -moz-animation-delay:0.6s;
}

#ballsWaveIMG_2{
  left:11px;
  animation-delay:0.75s;
  -o-animation-delay:0.75s;
  -ms-animation-delay:0.75s;
  -webkit-animation-delay:0.75s;
  -moz-animation-delay:0.75s;
}

#ballsWaveIMG_3{
  left:22px;
  animation-delay:0.9s;
  -o-animation-delay:0.9s;
  -ms-animation-delay:0.9s;
  -webkit-animation-delay:0.9s;
  -moz-animation-delay:0.9s;
}

#ballsWaveIMG_4{
  left:34px;
  animation-delay:1.05s;
  -o-animation-delay:1.05s;
  -ms-animation-delay:1.05s;
  -webkit-animation-delay:1.05s;
  -moz-animation-delay:1.05s;
}

#ballsWaveIMG_5{
  left:45px;
  animation-delay:1.2s;
  -o-animation-delay:1.2s;
  -ms-animation-delay:1.2s;
  -webkit-animation-delay:1.2s;
  -moz-animation-delay:1.2s;
}

#ballsWaveIMG_6{
  left:56px;
  animation-delay:1.35s;
  -o-animation-delay:1.35s;
  -ms-animation-delay:1.35s;
  -webkit-animation-delay:1.35s;
  -moz-animation-delay:1.35s;
}

#ballsWaveIMG_7{
  left:67px;
  animation-delay:1.5s;
  -o-animation-delay:1.5s;
  -ms-animation-delay:1.5s;
  -webkit-animation-delay:1.5s;
  -moz-animation-delay:1.5s;
}

#ballsWaveIMG_8{
  left:78px;
  animation-delay:1.64s;
  -o-animation-delay:1.64s;
  -ms-animation-delay:1.64s;
  -webkit-animation-delay:1.64s;
  -moz-animation-delay:1.64s;
}



@keyframes ballsWaveIMG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-o-keyframes ballsWaveIMG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-ms-keyframes ballsWaveIMG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-webkit-keyframes ballsWaveIMG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-moz-keyframes ballsWaveIMG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}
/*image*/
#circularG{
  position:relative;
  width:58px;
  height:58px;
  margin: auto;
}

.circularG{
  position:absolute;
  background-color:rgb(249,141,0);
  width:14px;
  height:14px;
  border-radius:9px;
  -o-border-radius:9px;
  -ms-border-radius:9px;
  -webkit-border-radius:9px;
  -moz-border-radius:9px;
  animation-name:bounce_circularG;
  -o-animation-name:bounce_circularG;
  -ms-animation-name:bounce_circularG;
  -webkit-animation-name:bounce_circularG;
  -moz-animation-name:bounce_circularG;
  animation-duration:1.1s;
  -o-animation-duration:1.1s;
  -ms-animation-duration:1.1s;
  -webkit-animation-duration:1.1s;
  -moz-animation-duration:1.1s;
  animation-iteration-count:infinite;
  -o-animation-iteration-count:infinite;
  -ms-animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-iteration-count:infinite;
  animation-direction:normal;
  -o-animation-direction:normal;
  -ms-animation-direction:normal;
  -webkit-animation-direction:normal;
  -moz-animation-direction:normal;
}

#circularG_1{
  left:0;
  top:23px;
  animation-delay:0.41s;
  -o-animation-delay:0.41s;
  -ms-animation-delay:0.41s;
  -webkit-animation-delay:0.41s;
  -moz-animation-delay:0.41s;
}

#circularG_2{
  left:6px;
  top:6px;
  animation-delay:0.55s;
  -o-animation-delay:0.55s;
  -ms-animation-delay:0.55s;
  -webkit-animation-delay:0.55s;
  -moz-animation-delay:0.55s;
}

#circularG_3{
  top:0;
  left:23px;
  animation-delay:0.69s;
  -o-animation-delay:0.69s;
  -ms-animation-delay:0.69s;
  -webkit-animation-delay:0.69s;
  -moz-animation-delay:0.69s;
}

#circularG_4{
  right:6px;
  top:6px;
  animation-delay:0.83s;
  -o-animation-delay:0.83s;
  -ms-animation-delay:0.83s;
  -webkit-animation-delay:0.83s;
  -moz-animation-delay:0.83s;
}

#circularG_5{
  right:0;
  top:23px;
  animation-delay:0.97s;
  -o-animation-delay:0.97s;
  -ms-animation-delay:0.97s;
  -webkit-animation-delay:0.97s;
  -moz-animation-delay:0.97s;
}

#circularG_6{
  right:6px;
  bottom:6px;
  animation-delay:1.1s;
  -o-animation-delay:1.1s;
  -ms-animation-delay:1.1s;
  -webkit-animation-delay:1.1s;
  -moz-animation-delay:1.1s;
}

#circularG_7{
  left:23px;
  bottom:0;
  animation-delay:1.24s;
  -o-animation-delay:1.24s;
  -ms-animation-delay:1.24s;
  -webkit-animation-delay:1.24s;
  -moz-animation-delay:1.24s;
}

#circularG_8{
  left:6px;
  bottom:6px;
  animation-delay:1.38s;
  -o-animation-delay:1.38s;
  -ms-animation-delay:1.38s;
  -webkit-animation-delay:1.38s;
  -moz-animation-delay:1.38s;
}



@keyframes bounce_circularG{
  0%{
    transform:scale(1);
  }

  100%{
    transform:scale(.3);
  }
}

@-o-keyframes bounce_circularG{
  0%{
    -o-transform:scale(1);
  }

  100%{
    -o-transform:scale(.3);
  }
}

@-ms-keyframes bounce_circularG{
  0%{
    -ms-transform:scale(1);
  }

  100%{
    -ms-transform:scale(.3);
  }
}

@-webkit-keyframes bounce_circularG{
  0%{
    -webkit-transform:scale(1);
  }

  100%{
    -webkit-transform:scale(.3);
  }
}

@-moz-keyframes bounce_circularG{
  0%{
    -moz-transform:scale(1);
  }

  100%{
    -moz-transform:scale(.3);
  }
}

.detail-image th, .img-detail tr td{
  text-align: center;
}

/*load bar*/
#ballsWaveG{
  position:absolute;
  width:50px;
  height:29px;
  margin:auto;
  right:-53%;
  top:12px;
}
#ballsWaveI {
  position: absolute;
  width: 176px;
  height: 41px;
  margin: auto;
  top: 100%;
  right: 36%;
}
.ballsWaveI{
  position:absolute;
  top:0;

  width:22px;
  height:22px;
  animation-name:ballsWaveI;
  -o-animation-name:ballsWaveI;
  -ms-animation-name:ballsWaveI;
  -webkit-animation-name:ballsWaveI;
  -moz-animation-name:ballsWaveI;
  animation-duration:1.5s;
  -o-animation-duration:1.5s;
  -ms-animation-duration:1.5s;
  -webkit-animation-duration:1.5s;
  -moz-animation-duration:1.5s;
  animation-iteration-count:infinite;
  -o-animation-iteration-count:infinite;
  -ms-animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-iteration-count:infinite;
  animation-direction:normal;
  -o-animation-direction:normal;
  -ms-animation-direction:normal;
  -webkit-animation-direction:normal;
  -moz-animation-direction:normal;
  border-radius:11px;
  -o-border-radius:11px;
  -ms-border-radius:11px;
  -webkit-border-radius:11px;
  -moz-border-radius:11px;
}

#ballsWaveI_1{
  left:0;
  animation-delay:0.6s;
  -o-animation-delay:0.6s;
  -ms-animation-delay:0.6s;
  -webkit-animation-delay:0.6s;
  -moz-animation-delay:0.6s;
}

#ballsWaveI_2{
  left:22px;
  animation-delay:0.75s;
  -o-animation-delay:0.75s;
  -ms-animation-delay:0.75s;
  -webkit-animation-delay:0.75s;
  -moz-animation-delay:0.75s;
}

#ballsWaveI_3{
  left:44px;
  animation-delay:0.9s;
  -o-animation-delay:0.9s;
  -ms-animation-delay:0.9s;
  -webkit-animation-delay:0.9s;
  -moz-animation-delay:0.9s;
}

#ballsWaveI_4{
  left:66px;
  animation-delay:1.05s;
  -o-animation-delay:1.05s;
  -ms-animation-delay:1.05s;
  -webkit-animation-delay:1.05s;
  -moz-animation-delay:1.05s;
}

#ballsWaveI_5{
  left:88px;
  animation-delay:1.2s;
  -o-animation-delay:1.2s;
  -ms-animation-delay:1.2s;
  -webkit-animation-delay:1.2s;
  -moz-animation-delay:1.2s;
}

#ballsWaveI_6{
  left:110px;
  animation-delay:1.35s;
  -o-animation-delay:1.35s;
  -ms-animation-delay:1.35s;
  -webkit-animation-delay:1.35s;
  -moz-animation-delay:1.35s;
}

#ballsWaveI_7{
  left:132px;
  animation-delay:1.5s;
  -o-animation-delay:1.5s;
  -ms-animation-delay:1.5s;
  -webkit-animation-delay:1.5s;
  -moz-animation-delay:1.5s;
}

#ballsWaveI_8{
  left:154px;
  animation-delay:1.64s;
  -o-animation-delay:1.64s;
  -ms-animation-delay:1.64s;
  -webkit-animation-delay:1.64s;
  -moz-animation-delay:1.64s;
}



@keyframes ballsWaveI{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-o-keyframes ballsWaveI{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-ms-keyframes ballsWaveI{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-webkit-keyframes ballsWaveI{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-moz-keyframes ballsWaveI{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}
/*NEW*/
.ballsWaveG{
  position:absolute;
  top:0;
  background-color:rgb(3,60,91);
  width:10px;
  height:10px;
  animation-name:ballsWaveG;
  -o-animation-name:ballsWaveG;
  -ms-animation-name:ballsWaveG;
  -webkit-animation-name:ballsWaveG;
  -moz-animation-name:ballsWaveG;
  animation-duration:1.5s;
  -o-animation-duration:1.5s;
  -ms-animation-duration:1.5s;
  -webkit-animation-duration:1.5s;
  -moz-animation-duration:1.5s;
  animation-iteration-count:infinite;
  -o-animation-iteration-count:infinite;
  -ms-animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-iteration-count:infinite;
  animation-direction:normal;
  -o-animation-direction:normal;
  -ms-animation-direction:normal;
  -webkit-animation-direction:normal;
  -moz-animation-direction:normal;
  border-radius:5px;
  -o-border-radius:5px;
  -ms-border-radius:5px;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
}

#ballsWaveG_1{
  left:0;
  animation-delay:0.6s;
  -o-animation-delay:0.6s;
  -ms-animation-delay:0.6s;
  -webkit-animation-delay:0.6s;
  -moz-animation-delay:0.6s;
}

#ballsWaveG_2{
  left:10px;
  animation-delay:0.75s;
  -o-animation-delay:0.75s;
  -ms-animation-delay:0.75s;
  -webkit-animation-delay:0.75s;
  -moz-animation-delay:0.75s;
}

#ballsWaveG_3{
  left:19px;
  animation-delay:0.9s;
  -o-animation-delay:0.9s;
  -ms-animation-delay:0.9s;
  -webkit-animation-delay:0.9s;
  -moz-animation-delay:0.9s;
}

#ballsWaveG_4{
  left:29px;
  animation-delay:1.05s;
  -o-animation-delay:1.05s;
  -ms-animation-delay:1.05s;
  -webkit-animation-delay:1.05s;
  -moz-animation-delay:1.05s;
}

#ballsWaveG_5{
  left:38px;
  animation-delay:1.2s;
  -o-animation-delay:1.2s;
  -ms-animation-delay:1.2s;
  -webkit-animation-delay:1.2s;
  -moz-animation-delay:1.2s;
}

#ballsWaveG_6{
  left:48px;
  animation-delay:1.35s;
  -o-animation-delay:1.35s;
  -ms-animation-delay:1.35s;
  -webkit-animation-delay:1.35s;
  -moz-animation-delay:1.35s;
}

#ballsWaveG_7{
  left:58px;
  animation-delay:1.5s;
  -o-animation-delay:1.5s;
  -ms-animation-delay:1.5s;
  -webkit-animation-delay:1.5s;
  -moz-animation-delay:1.5s;
}

#ballsWaveG_8{
  left:67px;
  animation-delay:1.64s;
  -o-animation-delay:1.64s;
  -ms-animation-delay:1.64s;
  -webkit-animation-delay:1.64s;
  -moz-animation-delay:1.64s;
}



@keyframes ballsWaveG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-o-keyframes ballsWaveG{
  0%{
    background-color:rgbrgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-ms-keyframes ballsWaveG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-webkit-keyframes ballsWaveG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}

@-moz-keyframes ballsWaveG{
  0%{
    background-color:rgb(3,60,91);
  }

  100%{
    background-color:rgb(255,255,255);
  }
}
/*end load*/
.has-error .selecting{
  width:30%;
}
.has-feedback .form-control{
  padding-right:0!important;
}
.no-padding{
  padding:0!important;
}
.search-competitors .active{
  border: 2px solid #F98F00!important;
  font-weight: bold !important;
}
.error{
  border:1px solid #a94442;
}
.product-check table .details-row td[class*=col-]
{
  position: relative!important;
  display: inline-block!important;
  float: none;
}
/*
table td[class*=col-], table th[class*=col-]{
    position: relative!important;
    display: inline-block!important;
    float: none;
}*/
tr .no-padding p{
  margin: 0 0 2px;
}
.product-check{
  border-top:2px solid #00699C;
}
.alert {
  position: absolute;
  border-radius: 0px;
  top: -15px;
  width:100%;
  left:0;
}
.company-info blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 14px;
  border-left: 1px solid #0792D5;
  color: #003C5A;
  margin-top: 40px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
/*check box*/

.itemCustomer{
  min-height:100px;
  position:relative;

}
.md-checkbox {
  position: relative;
  border: solid 1px #cccccc;
  padding: 15px;
  display: block;
  min-height: 130px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.md-checkbox input[type=checkbox] {
  visibility: hidden;
  position: absolute;


}
.md-checkbox label {
  cursor: pointer;
  padding-right: 30px;
}
.md-checkbox label > span {
  display: block;
  position: absolute;
  left: auto;
  right:20px;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.md-checkbox label > span.inc {
  background: #fff;
  left: auto;
  right:20px;
  top:15px;
  height: 20px;
  width: 20px;
  opacity: 0;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.md-checkbox label > .box {
  top: 50px;
  left:auto;
  right:20px;
  border: 2px solid #cccccc;
  height: 20px;
  width: 20px;
  z-index: 5;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.md-checkbox label > .checkk {
  top: 45px;
  left: auto;
  right: 22px;
  width: 11px;
  height: 23px;
  border: 2px solid #0792d5;
  border-top: none;
  border-left: none;
  opacity: 0;
  z-index: 5;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  z-index: 10;
}
.md-checkbox label > span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}
/*
.md-checkbox input[type=checkbox]:checked ~ label > .box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}*/

.md-checkbox input[type=checkbox]:checked ~ label > .checkk {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}
.md-checkbox.has-error label {
  color: #F3565D;
}
.md-checkbox.has-error label > .box {
  border-color: #F3565D;
}
.md-checkbox.has-error label > .checkk {
  border-color: #F3565D;
}
.itemInput{
  margin:20px;

}
.itemInput .form-inline .form-group  .form-control {
  display: inline;
  width: 48%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-bottom: 1px solid #0792D5;
  border-radius: 0px;
  border-right: 0 transparent;
  border-top: 0;
  border-left: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: #fff!important;
  background-image: none;
  color: rgba( #337ab7, .7 );
}
.itemInput .form-inline .form-group{
  display:block;
}

.itemInput .form-inline .form-group label{
  width:15%;
}
.attribution{
  margin-top: 25px;
  font-weight: 500;
}

.fileinput-preview img{

  padding: 0vh 2vh;
  margin: auto;
  text-align: center;
  display: block;

}
.fileinput-new .btn-default {
  color: #fff;
  background-color: #0792d5;
  border-color: transparent;
  padding: 3px 14px;
}
.cstm-in{
  color: #fff;
  background-color: #0792d5;
  border-color: transparent;
  padding: 3px 40px;

}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.custom-upload .btn-default {
  border-radius:0px;
}
.custom-upload{
  text-align:center;
  cursor:pointer;
  margin-bottom: 20px;
}
.company-info blockquote .form-control{
  display: inline;
  width: 50%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-bottom: 1px solid #0792D5;
  border-radius: 0px;
  border-right: 0 transparent;
  border-top: 0;
  border-left: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.input-parent{
  width: 21%;
  position: relative;
  display: inline-block;
}
.registerBtn {
  float: right;
  width: 40%;
  height: 34px;
  text-align: center;
  margin: 0 auto;
  background:#0792d5;
  color:#fff;
  border: 0px;
}
.order-info{

  margin-top: 2.5%;
  background-color: #cdcdcd;
  margin-bottom: 15px;
  padding: 1vh;

}
.order-status{
  float: right;
  text-align: right;
}
.order-info span{
  color:#005680;
}
.items-info-history tr th, td{
  text-align:center;
  display: table-cell;
}
.qu
.items-info tr th, td{
  text-align:center;
  display:inline-block;
}
.qua p{
  border: 2px solid #CCCCCC;
  padding: 8px;
}
.info-products{
  margin-bottom:15px;
}
.info-products th{
  text-align:center;
}
/*.active-sidebar2{
  background-color: #0792d5!important;
}*/
.search-competitors .search-id{
  padding: 2.5vh 0vh 2.5vh;
}
.section-title-users{
  text-align:center;
  color:#00699C;
}
.add-center{
  text-align:center;
}
.add-center .registerBtn {
  float:none;
}
.users-input{
  text-align:center;
}
.users-input .form-inline .form-group label {
  text-align: left;
}
.help-inline{
  position: absolute;
  left: 50%;
  right: 0;
  color: red;
  text-align: -webkit-left;
  padding: 12px;
}

.add-logo .thumbnail img{
  padding: 2vh 0vh;
  max-width:100px;
}
.submit-logo .btn-default{
  color: #fff;
  background-color: #0792d5;
  border-color: transparent;
  width:20%;
}
.submit-logo{
  text-align:center;
}

@media (min-width: 1281px) and (max-width: 1383px){
  .left-item {
    background-color: #00699C;
    padding: 3.2vh 11vh;
    margin-bottom: 15px;

  }
}
.info-products-history th{
  display: table-cell!important;
}

#modalOrder .form-control {
  color: rgba( #337ab7, .7 );
  font-size: 12px;}
#modalOrder .form-control::placeholder{
  color: rgba( #337ab7, .7 );
  font-size: 12px;
}
.modal-header{
  background-color:#337ab7;
}
.close{
  color:#fff!important;
}
.modal-title{
  color:#fff!important;
}
.modal-body p, .checkbox{
  color:#337ab7!important;
}
.modal-footer .btn-default {
  color: #337ab7;
  background-color: #fff;
  border-color: #337ab7;
}
.pickUP{
  color: rgba( #337ab7, .7 )!important;
}
.pickUP input:-webkit-autofill, .pickUP textarea:-webkit-autofill, .pickUP select:-webkit-autofill {
  background-color: #fff!important;
  background-image: none;
  color: rgba( #337ab7, .7 )!important;
}

//Flex Code

.parent-div{
  display: flex;
  flex-wrap: wrap;
}

.parent-div>div,.parent-div>a{
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: row;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.parent-div>div>div {
  display: flex;
  flex-grow: 1;
  width: 33%;
  flex-wrap: wrap;
  padding-right: 0px;
  padding-left: 7px;
}

.parent-div>div>div>div {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  margin: 0 5px;
  max-height:300px;
  padding:0!important;
  min-height: 230px;
}
.parent-div>div>div>div.despartit{
  height: 10px;
  min-height: 10px;
}
.parent-div>div>div>div a {
  width: 100%;
}
.parent-div .left-item h2{
  width: 100%;
}
.align-boxes{
  margin-top:-10px;
  margin-bottom:-10px!important;
}
@media screen and (max-width: 1038px){
  .parent-div > div, .parent-div > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: row;
    margin-bottom: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .left-item {
    background-color: #00699C;
    padding: 1.3vh 12vh;
    margin-bottom: 15px;
    height: auto;
  }
  .left-icon {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 225px;
    height: 200px;
  }

  .right-icon {
    text-align: center;
    background: #00699C;
    display: block;
    padding: 0vh 2vh;
  }
  .no-padding-xs{
    padding:0!important;
  }
}
@media screen and (max-width: 480px) {
  .parent-div>div>div {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-wrap: wrap;
  }
  .margin-solo{
    margin-bottom:10px!important;
  }
  .left-item h2 {
    font-size: 12px;
    text-align: center;
    padding-top: 14px;
    margin-top: 0%;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-weight: 100;
    margin-bottom: 10px;
  }
  .left-item {
    background-color: #00699C;
    padding: 0.3vh 10vh;
  }
  .right-icon {
    text-align: center;
    background: #00699C;
    display: block;
    padding: 1vh 2vh;
  }
  .no-padding-competitor{
    padding:0!important;
  }
}
.no-padding-competitor{
  padding-right:0!important;
}
.panel-register{
  background-color:#00699C!important;
}
.search-bar{
  background: #0792d5;
  padding: 1vh;
}
.search-bar .form-control{
  display: inline;
  width: 50%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-bottom: 1px solid #00699C;
  border-radius: 0px;
  border-right: 0 transparent;
  border-top: 0;
  border-left: 0;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.search-bar .form-group {
  display:block!important;
}
.search-bar .srcs .src-buton-cst{
  background: #F98F00;
  padding: 5px 30px;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
  color:#ffffff!important;
}
.search-bar .srcs .src-buton-cst:hover {
  background: darken( #F98F00, 10% )!important;
}
.srcs .frm-src input[type="text"]::-webkit-input-placeholder {
  color:#005680!important;
  font-size: 10px;
}

// Radu
[v-cloak] {
  display: none;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-header button{
  background: transparent;
  border: none;
  font-weight: 600;
  color: #fff;
}
.modal-body {
  margin: 20px 0;
}
.modal-body img{
  width: 100%;
}
.modal-default-button {
  float: right;
}
.zoompicture{
  cursor: pointer;
  border: 1px solid #CCCCCC;
}
.calendar-input{
  display: inline!important;
  width: auto!important;
}
.sub-calendar{
  color: #fff!important;
  background-color: #005680!important;
  border-color: #005680!important;
  padding: 0.5vh 3vh!important;
  border: 0px solid transparent!important;
  border-radius: 0px!important;
  font-size: 15px!important;
  font-weight: 300!important;
}
.sub-calendar:hover {
  background: #c67200!important;
}
.form-calendar {
  text-align: center!important;
}
.info-links{
  text-align: center;
  color: #005680;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto" !important;
  border: 2px solid #005680;
}
.form-calendar  strong{
  color: #005680;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto" !important;
}
.users-links-info {
  color: #005680;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto" !important;
  text-align: center;
}
@media screen and (max-width: 580px){
  .over-xs{
    overflow: scroll;
    padding: 0;
    overflow-y: hidden;
  }
  #style-1::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fff;
  }

  #style-1::-webkit-scrollbar
  {
    width: 5px;
    background-color: #fff;
    height:5px;

  }

  #style-1::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #00699C;
  }
}

.middle-title{
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  padding: 30px;
}
.middle-title h1{
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  font-family: "Roboto" !important;
  margin:0;
  text-align: center;
}
.fa-upload{
  margin-right:5px!important;

}
.import input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #999999!important;
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
  font-family: "Roboto" !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #999999!important;
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
  font-family: "Roboto" !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #999999!important;
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
  font-family: "Roboto" !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #999999!important;
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
  font-family: "Roboto" !important;
}
.new-buttons .new-up, .new-buttons .new-down{
  background-color: #0792d5;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.new-buttons .new-down:hover {
  background: #c67200;
}
.new-buttons .down-order{
  background: #00699C;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 300;
  padding: 2px 12px;
}
.new-buttons{
  margin: 0;
}
.items-info-history{
  margin-bottom:15px;
}

.custom-file-upload {
  display: inline-block;

  cursor: pointer;
}
.button-info .new-up{
  background-color: #0792d5;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.new-down{
  background-color: #0792d5;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.new-down:hover, .new-down.focus, .new-down:focus, .new-down:hover, .new-down:active{
  background-color: #0792d5;
  display: inline-block;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  padding: 2vh 8vh;
  border: 0px solid transparent;
  border-radius: 0px;
  font-size: 17px;
  font-weight: 300;
}
.new-up p{
  margin:0!important;
}
.info-inst .instructions{
  background: none;
  color:#F98F00;
  font-size: 18px;
  padding: 0;

}
.info-inst .instructions:hover, .info-inst .instructions:active, .info-inst .instructions:focus, .info-inst .instructions:active
{
  background: none;
  color:#0792d5;
  font-size: 18px;
  padding: 0;
  box-shadow: inset 0 0px 0px rgba(0,0,0,.125);
  outline: 0px auto -webkit-focus-ring-color;
}
.info-inst h3{
  margin: 0 auto;
  text-align: center;
  color: #00699C;
  font-weight: 300;
  font-size: 20px;
  font-family: "Roboto" !important;
}
.modal-info-up{
  margin: 0 auto;
  text-align: center;
  color: #00699C;
  font-weight: 400;
  font-size: 18px;
  font-family: "Roboto" !important;
}
.preview{
  text-align: center;
}
.preview img, .banner_web img{
  max-width: 100%;
}
.navbar-collapse.col-lg-4{
  width: 50%;
  float: right;

}
.text-center{
  text-align: center;
  margin: auto;
  float: none;
}
.navbar-inverse .navbar-toggle {
  border-color: #e6e6e6;
}

.navbar-inverse .navbar-toggle .icon-bar{
  background-color: #00699C;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover{
  background-color: #e6e6e6;
  border-color: #00699C;
}
@media (max-width: 1000px) {
  .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand{
    padding: 5px 10px!important;
  }
  .order2{
    position: absolute;
    right: 0
  }
  .order3{
    text-align: center;
    margin: auto;
  }

}
.order3{
  text-align: center;
  margin: auto;
}
@media (max-width: 1200px) {
  .navbar-collapse.col-lg-4 {
    width: 100%;
  }
  .navbar-nav {
    float: right !important;
    margin-top: 7.5px;
    display: -webkit-inline-box;
    text-align: right;
  }

  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }

  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form{
  border-color: transparent;
}
.p-0{
  padding: 0!important;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
/* style 1 */

label.file-new-ex svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}

label.file-new-ex {
  color: #ffffff;
  background-color: #F98F00;
  max-width: 80%;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  font-family: "Roboto" !important;
  border: 1px solid transparent;
}
.submit-logo .btn-default.ex-btn{
  max-width: 80%;
  padding: 0.625rem 1.25rem;
  font-size: 16px;
  width: 50%;
}
.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
  background-color: #c67200;
}
.nav .cdndropdown{
  position: absolute;
  top:40px;
}
.dropdown-toggle.cdn-drop {
  color: #00699C;
  background-color: transparent !important;
  font-size: 22px;

}
.open .dropdown-toggle.cdn-drop {
  color: #00699C;
  background-color: transparent !important;
  font-size: 22px;
}
.dropdown-toggle.cdn-drop .caret{
  font-size: 20px;
}
.cdn-category.dropdown-menu{
  background: #ffffff;
}
.dropdown-menu.cdn-category li a{
  color:#00699C;
}
@media screen and (max-width: 689px){
  .nav .cdndropdown{
    position: relative;
    top:0px;
    float: none;
    text-align: center;
  }
}
.btn-save{
  color:#ffffff;
  background: #0792d5;
  border: 0;
}
.btn-save:hover{
  background: #0792d5;
  border: 0;

}
.btn-save.btn-success.focus, .btn-save.btn-success:focus {
  background: #0792d5;
  border: 0;

}
.cdn-table .details-row td{
  font-size: 15px!important ;
}
.formQ1 .form-group .control-label{
  color:#00699C;
  background: #fff;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
}
.formQ1 .form-group{
  margin-bottom: 5px;
}

.formQ1 p{
  font-size: 20px;
  color: #00699C;
  text-align: center;
}
.formQ1 .form-control{
  background-color: #E6EDF0;
  color:#00699C;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-image: none;
  border: 0px solid #ccc;
  border-radius: 0px;

}

.pl-0{
  padding-left: 0;
}
.left-full{
  background-color: #00699C;
}
.right-full div{
  background-color: #00699C;
}

.parent-div .full-item.left-full:hover {
  background-color: #29ABE2;
  text-decoration: none;
}
.parent-div .full-item.right-full:hover {
  background-color: transparent;
  text-decoration: none;
}
.parent-div .full-item.right-full:hover div{
  background-color: #29ABE2;
  text-decoration: none;
}

.m-0{
  margin:0px!important;
}
.parent-div{
  height: 100%;
}
.parent-div .full-item {
  margin-bottom: 10px!important;
  display: flex ;
  flex-direction: row ;
  justify-content: center ;
  align-items: stretch ;

}
.parent-div .full-item.left-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.child-div.right-full {
  padding-left: 22px;
  padding-right: 0px;
}
.right-full div{
  background-color: transparent;
}

.right-full a{
  padding-left: 24px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  /*height: 100%;*/
  align-content: center;
  align-items: center;
  background: #00699C;
  margin-bottom: 10px;
}

#app  .parent-div .full-item h2{
  font-size: 20px;
  text-align: left;
  padding-top: 0px;
  margin-top: 0px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-weight: 100;
  /* position: relative; */
  top: 0;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  /* display: inline-block;*/
  margin: 0
}
.parent-div .full-item.right-full:hover div{
  background-color: transparent!important;
}

.parent-div .full-item.right-full a:hover {
  background-color: #29ABE2;
  text-decoration: none;
}
.left-item.item{
  padding: 0!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px
}
.parent-div .full-item img{
  padding: 0!important;
}
.right-icon{
  text-align: center;
  background: #00699C;
  display: block;
  max-height: 100%!important;
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 10px!important;
  max-height: auto;
  width: 100%;
  margin: 0 0 10px 0!important;
}

.show-mobile-only {
  display: none; /* Hide by default */
}

@media (max-width: 768px) {
  .show-mobile-only {
    display: block; /* or 'inline-block', 'flex', etc., depending on your layout needs */
  }
}

.m-0{
  margin:0px!important;
}
.parent-div{
  height: 100%;
  max-height: 80vh;
}
.parent-div .full-item {
  margin-bottom: 10px!important;
  display: flex ;
  flex-direction: row ;
  justify-content: center ;
  align-items: stretch ;

}
.parent-div .full-item.left-full {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.right-full div{
  background-color: transparent;
}

.right-full a{
  padding-left: 24px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  /*height: 100%;*/
  align-content: center;
  align-items: center;
  background: #00699C;
  margin-bottom: 10px;
}

#app  .parent-div .full-item h2{
  font-size: 20px;
  text-align: left;
  padding-top: 0px;
  margin-top: 0px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-weight: 100;
  /* position: relative; */
  top: 0;
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  /* display: inline-block;*/
  margin: 0
}
.parent-div .full-item.right-full:hover div{
  background-color: transparent!important;
}

.parent-div .full-item.right-full a:hover {
  background-color: #29ABE2;
  text-decoration: none;
}
.left-item.item{
  padding: 0!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px
}
.parent-div .full-item img{
  padding: 0!important;
  width: 100%;
  max-width: 60px!important;
  height: 80px!important;
  font-size: 80px;

}
.right-icon{
  text-align: center;
  background: #00699C;
  display: block;
  height: auto!important;
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 0px!important;
  margin: 0 0 10px 0!important;
  justify-content: center;
}
.right-icon img{
  max-width: 30px!important;
}
@media only screen and (min-width: 1281px) and (max-width: 1680px){
  .right-icon img{
    max-width: 30px!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
  }
}
.child-div.h-200{
    
    height: 200px;

}
.child-div .left-link-abs{
    position: absolute; 
    left: 0; 
    top: 0; 
    width: 100%; 
    height: 100%; 
    text-decoration: none;
}
.banner_web{
    
    padding: 0;
    margin-bottom: 10px;

}
